<template lang="pug">
  .document
      v-container
        v-row.sign-row
          v-col(sm="12")
          v-card(v-if="documents")
            v-card-title Contract list for
                strong &nbsp;{{user.name}}
            v-card-text
                v-data-table(
                    :headers="documentHeaders"
                    :items="documents"
                    hide-default-footer
                    item-key="uuid"
                    :items-per-page="-1"
                )
                    template(#item.document_name="{ item }")
                        router-link.document-name(:to="`/contract/sign/${item.uuid}`") {{item.document_name || '[Untitled]'}}
                    template(#item.status="{ item }")
                        .chip-holder
                            v-chip(:color="statuses.colors[item.status]" :class="statuses.class[item.status]" text-color="white")
                                v-avatar(left)
                                    v-icon(:class="statuses.class[item.status]") {{statuses.icons[item.status]}}
                                | {{statuses.names[item.status]}}
                    template(#item.created_at="{ item }")
                        span.date(v-if="item.created_at") {{formatDate(item.created_at) }}
                        span.n-a(v-else) N/A
                    template(#item.read_at="{ item }")
                        span.date(v-if="item.read_at") {{formatDate(item.read_at) }}
                        span.n-a(v-else) N/A
                    template(#item.signed_at="{ item }")
                        span.date(v-if="item.signed_at") {{formatDate(item.signed_at) }}
                        span.n-a(v-else) N/A

          v-card(v-else)
            v-card-text
                .loader
                    Spinner

</template>

<script>
import api from '@/api';
import {format} from "date-fns";
import Spinner from "../../components/Spinner";

export default {
    components: {Spinner},
    data(){
        return {
            id: this.$route.params.id,
            documents: null,
            user: null,
            documentHeaders: [
                {text: 'Title', value: 'document_name', sortable: true},
                {text: 'Status', value: 'status', sortable: true},
                {text: 'Sent', value: 'created_at', sortable: true},
                {text: 'Read', value: 'read_at', sortable: true},
                {text: 'Signed', value: 'signed_at', sortable: true},
            ],
            statuses: {
                'colors':['secondary', 'primary', 'amber', 'green'],
                'names': ['Draft', 'Unread', 'Read', 'Signed'],
                'icons': ['mdi-pencil', 'mdi-email-outline', 'mdi-eye-outline', 'mdi-check'],
                'class': ['draft', 'sent', 'read', 'signed']
            },
        }
    },

    methods: {
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
    },

    async beforeMount(){
        const data = await api.getDocumentsForGuest(this.id);
        this.documents = data.documents;

        this.user = data.person;

        this.$store.commit('setSelectedPerson', {
            name: this.user.name,
            email: this.user.email,
        })
    }
}
</script>
<style lang="scss" scoped>
.v-card{
    width: 100%;
}

a.document-name{
    text-decoration: none;
    font-weight: bold;

    &:hover{
        text-decoration: underline;
    }
}

.v-chip{
    opacity: 0.75;
}

.loader{
    padding-top: 15%;
    padding-bottom: 15%;
    text-align: center;
}
</style>
