var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document"},[_c('v-container',[_c('v-row',{staticClass:"sign-row"},[_c('v-col',{attrs:{"sm":"12"}}),(_vm.documents)?_c('v-card',[_c('v-card-title',[_vm._v("Contract list for"),_c('strong',[_vm._v(" "+_vm._s(_vm.user.name))])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.documentHeaders,"items":_vm.documents,"hide-default-footer":"","item-key":"uuid","items-per-page":-1},scopedSlots:_vm._u([{key:"item.document_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"document-name",attrs:{"to":("/contract/sign/" + (item.uuid))}},[_vm._v(_vm._s(item.document_name || '[Untitled]'))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"chip-holder"},[_c('v-chip',{class:_vm.statuses.class[item.status],attrs:{"color":_vm.statuses.colors[item.status],"text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{class:_vm.statuses.class[item.status]},[_vm._v(_vm._s(_vm.statuses.icons[item.status]))])],1),_vm._v(_vm._s(_vm.statuses.names[item.status]))],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))]):_c('span',{staticClass:"n-a"},[_vm._v("N/A")])]}},{key:"item.read_at",fn:function(ref){
var item = ref.item;
return [(item.read_at)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(item.read_at)))]):_c('span',{staticClass:"n-a"},[_vm._v("N/A")])]}},{key:"item.signed_at",fn:function(ref){
var item = ref.item;
return [(item.signed_at)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(item.signed_at)))]):_c('span',{staticClass:"n-a"},[_vm._v("N/A")])]}}],null,false,313924187)})],1)],1):_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"loader"},[_c('Spinner')],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }