<template lang="pug">
  .document
      v-container
        v-row.sign-row
          v-col(sm="10")
            Alert(color="primary" dense) Please make sure you read the contract carefully, and fill in the required fields marked in amber.
                span
                    strong &nbsp;{{filledFields}} / {{totalFields}}
                    | &nbsp;required fields filled.
          v-col.sign-holder(sm="2")
            v-spacer
            v-btn.sign(color="primary" @click="submit" :disabled="!requiredFilled") Sign contract
        v-row
          v-col
            Alert(color="amber" dense v-if="error") There was an error signing the contract. Please check all fields and try again.
        v-row
          v-col(sm="12")
            EditorPreview(v-model="document.html" v-if="document" :hideScheduleA="true")
          v-col(sm="12")
            v-card
              v-card-text
                div.schedule-a(v-html="document.songs_html" v-if="document")

</template>

<script>
import api from '@/api';
import EditorPreview from "../../components/EditorPreview";
import Alert from "../../components/Alert";

export default {
    components: {Alert, EditorPreview},
    data(){
        return {
            id: this.$route.params.id,
            document: null,
            error: false
        }
    },

    methods: {
        async submit(){
            this.$store.commit('showSpinner', true);
            const result = await api.signDocument({
                fields: this.$store.state.signedFields
            }, this.id);
            if(result === 'error'){
                this.error = true;
            }else{
                this.$router.push({name: 'contract_signed', params: {uuid: this.document.uuid}});
            }
        }
    },

    computed:{
        requiredFilled(){
            const values = Object.values(this.$store.state.requiredFields);
            console.log(values);
            return values.every(field => field === true);
        },
        totalFields(){
            const values = Object.values(this.$store.state.requiredFields);
            return values.length;
        },
        filledFields(){
            const values = Object.values(this.$store.state.requiredFields);
            return values.filter(value => value === true).length;
        }
    },

    async beforeMount(){
        this.document = await api.getDocumentForSigning(this.id);
        if(this.document.signed_at){
            this.$router.replace({ name: 'view_contract', params: { id: this.id } })
        }
        this.$store.commit('setSelectedPerson', {
            name: this.document.name,
            email: this.document.email,
        })
    }
}
</script>
<style lang="scss" scoped>
.document{

}

::v-deep .preview{
    padding: 0px;
}

.sign-row{
    margin-top: 10px;
}

::v-deep .v-btn.sign{
    height: 100%;
}

.sign-holder{
    align-items: center;
    display: flex;
}
</style>
