<template lang="pug">
  .document-holder
      v-container
        v-row
          v-col
            v-card
              v-card-text
                .document(v-html="document.html" v-if="document")
          v-col(sm="12")
            v-card
              v-card-text
                div.schedule-a(v-html="document.songs_html" v-if="document")
</template>

<script>
import api from '@/api';

export default {
    data(){
        return {
            id: this.$route.params.id,
            document: null
        }
    },

    async beforeMount(){
        this.document = await api.getDocumentForSigning(this.id);
        this.$store.commit('setSelectedPerson', {
            name: this.document.name,
            email: this.document.email,
        })
        if(!this.document.signed_at){
            this.$router.replace('/');
        }
    }
}
</script>

<style lang="scss" scoped>


::v-deep .v-card{
    margin-top: 35px;
}
</style>
